import React from 'react';
import {Link} from 'react-router-dom';

import {DOC_APPROVAL, DOC_OFFER, DOC_POLICY, INDEX} from '../../config/routes';

import mc from './mc.svg'
import logo from './OnlyCash-Logo-White.svg'
import visa from './visa.svg'

import cls from './Footer.module.scss'

const Footer = () => (
    <div className={cls.footer}>
        <div className={'content'}>
            <div className={cls.wrapper}>
                <div className={cls.logos}>
                    <Link to={INDEX}>
                        <img src={logo} alt={'logo'}/>
                    </Link>
                    <img src={visa} alt={'Payment'}/>
                    <img src={mc} alt={'Payment'}/>
                </div>
                <div className={cls.content}>
                    <p>Товарищество с ограниченной ответственностью «АйТи Консул»</p>
                    <p>Юридический адрес: 050042, Казахстан, город Алматы, Ауэзовский район, улица Рыскулбекова, здание 39а</p>
                    <p>БИН: 230740027370</p>
                    <p>ИИК: KZ368562203131793615</p>
                    <p>БИК: KCJBKZKX</p>
                    <p>Банк: АО Банк ЦентрКредит</p>
                    <p>Почта: info@onlycash.kz</p>

                    <p>Сайт https://onlycash.kz не является кредитной организацией и не выдает кредиты, займы и другие кредитные продукты пользователям сайта. Сайт является агрегатором финансовых продуктов различных кредитных организаций, имеющих все обязательные лицензии и сертификаты. Сайт не несет ответственность за любые заключенные договоры кредитования или условия. Расчеты носят приблизительный характер, окончательное условия уточняйте на сайте кредитной организации.
                    </p>

                    <p>
                        <a href={DOC_POLICY} target={'_blank'} rel={'noreferrer'}>
                            Политика обработки персональных данных
                        </a>
                    </p>
                    <p>
                        <a href={DOC_OFFER} target={'_blank'} rel={'noreferrer'}>
                            Правила и условия оферты
                        </a>
                    </p>
                    <p>
                        <a href={DOC_APPROVAL} target={'_blank'} rel={'noreferrer'}>
                            Согласие на получение ПКР
                        </a>
                    </p>

                    <p>Услуга оплаты на сайте OnlyCash осуществляется в соответствии с Правилами международных платежных систем Visa и MasterCard на принципах соблюдения конфиденциальности и безопасности совершения платежа, для чего используются самые современные методы проверки, шифрования и передачи данных по закрытым каналам связи. Ввод данных банковской карты осуществляется на защищенной платежной странице FreedomPay.</p>
                    <p>На странице для ввода данных банковской карты потребуется ввести номер карты, имя владельца карты, срок действия карты, трёхзначный код безопасности (CVV2 для VISA или CVC2 для MasterCard). Все необходимые данные пропечатаны на самой карте.</p>
                    <p>Трёхзначный код безопасности — это три цифры, находящиеся на обратной стороне карты.</p>
                    <p>Далее вы будете перенаправлены на страницу Вашего банка для ввода 3DSecure кода, который придет к Вам в СМС. Если 3DSecure код к Вам не пришел, то следует обратится в банк выдавший Вам карту.</p>
                    <p>Случаи отказа в совершении платежа:</p>
                    <p>○ банковская карта не предназначена для совершения платежей через интернет, о чем можно узнать, обратившись в Ваш Банк;</p>
                    <p>○ недостаточно средств для оплаты на банковской карте. Подробнее о наличии средств на банковской карте Вы можете узнать, обратившись в банк, выпустивший банковскую карту;</p>
                    <p>○ данные банковской карты введены неверно;</p>
                    <p>○ истек срок действия банковской карты. Срок действия карты, как правило, указан на лицевой стороне карты (это месяц и год, до которого действительна карта). Подробнее о сроке действия карты Вы можете узнать, обратившись в банк, выпустивший банковскую карту;</p>
                    <p>По вопросам оплаты с помощью банковской карты и иным вопросам, связанным с работой сайта, Вы можете обращаться по следующему телефону: +7 (702) 031 25 26.</p>
                    <p>Предоставляемая вами персональная информация (имя, адрес, телефон, e-mail, номер банковской карты) является конфиденциальной и не подлежит разглашению. Данные вашей кредитной карты передаются только в зашифрованном виде и не сохраняются на нашем Web-сервере.</p>
                    <p>Подробная информация об услуге «Персональный кредитный рейтинг», правила оплаты, получения и возврата изложены в Оферте, размещенной на Сайте.</p>


                </div>
            </div>
        </div>
    </div>
)

export default Footer