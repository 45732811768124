// INDEX
export const ROOT = '';
export const INDEX = '/';
export const REGISTRATION = '/registration';
export const CODE = '/code';
export const DONE = '/done';
export const DOC_POLICY = '/docs/Политика_обработки_персональных_данные_АйТи_Консул,_2023_OnlyCash.pdf';
export const DOC_OFFER = '/docs/Оферта_ПКБ_Казахстан_ОК_04122024.pdf';
export const DOC_APPROVAL = '/docs/Согласие_на_получение_ПКР_АйТи_Консул_2023.pdf';
export const NOT_FOUND = '/404';

