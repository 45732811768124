import React, {FC, useEffect} from 'react';
import {useInstance} from 'react-ioc';
import { useSearchParams } from 'react-router-dom';
import {useMutation} from '@apollo/client';
import cn from 'classnames'
import {observer} from 'mobx-react-lite';

import {Store} from '../../model/store/Store';
import {visitKZ, visitKZVariables} from '../../shared/graphql/__generated__/visitKZ';
import {VISIT_KZ} from '../../shared/graphql/visitKZ';

import {Banner} from './Banner/Banner';
import {Instruction} from './Instruction/Instruction';
import {Cards} from './Offers/Cards';

const Index: FC = observer(() => {

    const {auth, lead} = useInstance(Store)
    const [searchParams] = useSearchParams();
    const [visitKZ] = useMutation<visitKZ,visitKZVariables>(VISIT_KZ)

    useEffect( () => {
        if (searchParams.has('click_id')) {
            const click_id = searchParams.get('click_id')
            if (lead.click_id === null) {
                visitKZ({
                    variables: {
                        data: {
                            click_id: click_id!
                        }
                    },
                    onCompleted: (data) => {
                        lead.setUID(data.visitKZ?.UID!)
                        lead.setClickID(click_id)
                    }
                }).then()
            }
            lead.setClickID(click_id)
        }
    }, [searchParams, lead, visitKZ])

    return (
            <div className={cn(['content'])}>
                {auth.auth
                    ?
                        <Cards/>
                    :
                        <Banner/>
                }
                <Instruction/>
            </div>
    )
})

export default Index;



